import React from 'react'
import styled from 'styled-components'
import { useMenuModalOpen, useMenuModelToggle } from '../../state/application/hooks'

import Modal from '../Modal'
import Row, { RowBetween } from '../Row' //Row,
import Logo from '../../assets/images/LOGO.svg'
import CloseImg from '../../assets/images/close.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CloseIcon = styled.div`
  img {
    width: 36px;
    height: 36px;
  }
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const UpperSection = styled.div`
  position: relative;
  padding: 20px;
  padding-top: 5rem;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

// const Blurb = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: center;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   margin-top: 2rem;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     margin: 1rem;
//     font-size: 12px;
//   `};
// `

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      width: 6rem;
    }
  `};
`

const Tabs = styled.div`
  width: 100%;
  padding: 28px 24px;
`

const TabsItem = styled.div`
  color: #000;
  font-family: 'CocoSharp S';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
`

const StyledTab = styled(Link)`
  color: #000;
  font-family: 'CocoSharp S';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  text-decoration: none;
`

export default function MenuModal() {
  const languageModalOpen = useMenuModalOpen()
  const toggleLanguageModal = useMenuModelToggle()
  const { t } = useTranslation()
  // const location = useLocation()
  // const [activeTab, setActiveTab] = useState(location.pathname === '/explore' ? location.pathname : '/trade')
  // const handleTabClick = (tab: any) => {
  //   setActiveTab(tab)
  // }

  function ModalContent() {
    const toggleMenuModal = useMenuModelToggle()
    return (
      <UpperSection>
        <RowBetween>
          <Row>
            <HeaderElement>
              <Title href=".">
                <UniIcon>
                  <img src={Logo} alt="logo" />
                </UniIcon>
                {/* <TitleText>
                <img style={{ marginLeft: '4px', marginTop: '4px' }} src={isDark ? WordmarkDark : Wordmark} alt="logo" />
              </TitleText> */}
              </Title>
            </HeaderElement>
          </Row>
          <CloseIcon onClick={toggleMenuModal}>
            <img src={CloseImg} alt="logo" />
          </CloseIcon>
        </RowBetween>
        <Active></Active>
      </UpperSection>
    )
  }

  function Active() {
    const toggleMenuModal = useMenuModelToggle()
    return (
      <Tabs>
        <TabsItem style={{ marginBottom: '36px' }}>
          <StyledTab to="/trade" onClick={toggleMenuModal}>
            {t('trade')}
          </StyledTab>
        </TabsItem>
        <TabsItem>
          <StyledTab to="/explore" onClick={toggleMenuModal}>
            {t('explore')}
          </StyledTab>
        </TabsItem>
      </Tabs>
    )
  }

  return (
    <Modal isOpen={languageModalOpen} onDismiss={toggleLanguageModal} minHeight={105}>
      <Wrapper>
        <ModalContent></ModalContent>
      </Wrapper>
    </Modal>
  )
}
